.ruleGroup-addGroup {
  display: none;
}

.ruleGroup {
  background: #fff;
  border: none;
  padding: 0;
}

.ruleGroup-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.ruleGroup-body .rule {
  position: relative;
}

select.ruleGroup-combinators,
select.rule-fields,
select.rule-operators {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.695312 0.161133L3.83095 3.50581L6.96658 0.161133H0.695312Z' fill='%23323232'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 7px center;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 4px 14px 4px 4px;
  height: 28px;
}

select::-ms-expand {
  display: none;
}

select.ruleGroup-combinators {
  font-size: 10px;
  font-weight: 600;
  width: 60px;
}

select.rule-fields {
  width: 160px;
}

select.rule-operators {
  width: 100px;
}

select.rule-fields,
select.rule-operators {
  font-size: 12px;
  font-weight: 500;
  padding: 4px;
}

button.ruleGroup-addRule {
  width: auto;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  background: #0b1848;
  padding: 4px 12px;
  height: 28px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 600;
}

button.ruleGroup-addRule:disabled {
  opacity: 0.7;
}

button.ruleGroup-lock,
button.rule-lock {
  filter: grayscale(1);
  cursor: pointer;
}

button.ruleGroup-lock {
  font-size: 12px;
}

button.rule-lock {
  font-size: 10px;
}

input.rule-value,
input.react-datepicker-ignore-onclickoutside {
  height: 28px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px;
  height: 28px;
  width: 234px;
  font-size: 12px;
  font-weight: 500;
}

button.rule-remove {
  position: absolute;
  right: 0;
  top: 3px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #eee;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66683 4V10.6667H2.3335V4H7.66683ZM6.66683 0H3.3335L2.66683 0.666667H0.333496V2H9.66683V0.666667H7.3335L6.66683 0ZM9.00016 2.66667H1.00016V10.6667C1.00016 11.4 1.60016 12 2.3335 12H7.66683C8.40016 12 9.00016 11.4 9.00016 10.6667V2.66667Z' fill='%23DF6881' /%3E%3C/svg%3E");
}

button.rule-lock {
  position: absolute;
  right: 36px;
  top: 8px;
}

.filter-custom-select {
  width: 233px;
}

.filter-custom-select .react-multi-select__control {
  border: 1px solid #ddd !important;
  box-shadow: none !important;
  min-height: 28px;
}

.filter-custom-select .react-multi-select__value-container {
  padding: 0px 4px;
  font-size: 12px;
  font-weight: 500;
}

.filter-custom-select .react-multi-select__indicator {
  padding: 0 8px;
}

.filter-custom-select .react-multi-select__input-container {
  margin: 1px;
  padding-bottom: 0;
  color: #000;
}

.filter-custom-select .react-multi-select__menu {
  font-size: 12px;
}
