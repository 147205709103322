/**************************
 * Import public sans font
 **************************/
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/********************************
 * Import Tailwind Css utilities
 ********************************/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*********************
 * Add missing rendors
 *********************/
.gap-4 {
  gap: 1rem;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.status-dropdown {
  z-index: 100;
}

/*********************
 * Reset TailwindCss
 *********************/
*,
::before,
::after {
  @apply border-none;
}

/**********************************************************
 * Following utilities unable to genarate from TailwindCss
 **********************************************************/
.hover\:text-white:hover {
  @apply text-white;
}

.hover\:text-black-900:hover,
.hover\:text-black:hover {
  @apply text-black-900;
}

/**********************************************************
 * handle pager background image
 **********************************************************/

.form-control-small {
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='4' viewBox='0 0 7 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.695312 0.161133L3.83095 3.50581L6.96658 0.161133H0.695312Z' fill='%23323232'/%3E%3C/svg%3E%0A");
}

/**********************************************************
 * handle outline of form elements
 **********************************************************/
input,
select {
  @apply !outline-none;
}

.form-input {
  box-shadow: none !important;
}

.form-input-wrapper .form-input {
  @apply relative !bg-transparent;
  z-index: 2;
}
.form-input-wrapper .form-input:focus,
.form-input-wrapper.focused .form-input:focus,
.form-input-wrapper.focused .form-input {
  @apply !bg-white;
}

.form-input-wrapper label {
  z-index: 1;
  @apply duration-300 ease-out;
}

.form-input-wrapper .form-input:focus + label,
.form-input-wrapper.focused .form-input + label,
.form-input-wrapper .form-select:focus + label,
.form-input-wrapper.focused .form-select + label {
  @apply -top-[8px] bg-white text-12s;
  z-index: 2;
}

.form-date-picker .form-label {
  @apply -top-[8px] bg-white text-12s;
  z-index: 2;
}

.form-input-wrapper .form-input:active,
.form-input-wrapper .form-input:focus,
.form-input-wrapper .form-input:-webkit-autofill,
.form-input-wrapper .form-input:-webkit-autofill:hover,
.form-input-wrapper .form-input:-webkit-autofill:focus,
.form-input-wrapper .form-input:-webkit-autofill:active,
.form-input-wrapper .form-select:-webkit-autofill,
.form-input-wrapper .form-select:focus {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  @apply !bg-white;
}

.form-input-wrapper .form-select {
  padding-right: 30px;
  text-overflow: ellipsis;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%239e9e9e%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e') !important;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.form-select.no-arrow {
  background-image: none;
}

.form-select.no-arrow option {
  @apply py-2 focus:bg-gray-100 selection:bg-gray-100 hover:bg-gray-100;
}

/**********************************************************
 * handle tooltipvisibility
 **********************************************************/

.tooltip-icon:hover + .tooltip {
  @apply visible opacity-100;
}

/********************************************************************
 * Following Radio button styles unable to genarate from TailwindCss
 ********************************************************************/
.radio-input + .radio-mask,
.radio-input + .radio-mask-box {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  @apply relative inline-flex items-center justify-center bg-white border-solid border-gray-360;
}

.radio-input + .radio-mask-small {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  @apply relative inline-flex items-center justify-center bg-white border-gray-700 border-solid;
}

.radio-input + .radio-mask {
  @apply w-[14px] h-[14px] rounded-full border;
}

.radio-input + .radio-mask-small {
  @apply w-[12px] h-[12px] rounded-full border relative -top-[1px];
}

.radio-input + .radio-mask:before {
  content: '';
  @apply w-[6px] h-[6px] block bg-white rounded-full;
}

.radio-input + .radio-mask-small:before {
  content: '';
  @apply w-[4px] h-[4px] block bg-white rounded-full;
}

.radio-input:checked + .radio-mask {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  @apply border border-solid border-skyLighBlue bg-skyLighBlue;
}

.radio-input:checked + .radio-mask-small {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  @apply border border-solid border-primary-500 bg-primary-500;
}

.radio-input:checked + .radio-mask:before {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.radio-input:checked + .radio-mask-small:before {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
}

/*** box type button ***/
.radio-input + .radio-mask-box svg {
  @apply text-white;
}

.radio-input:checked + .radio-mask-box svg {
  @apply text-black-900;
}

/********************************************************************
 * Following Date pickerstyles unable to genarate from TailwindCss
 ********************************************************************/
.custom-picker {
  width: 100%;
  border: none;
}

.custom-picker * {
  outline: 0 !important;
}

.custom-picker .react-datepicker__month-container {
  width: 100%;
}

.custom-picker .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: 0;
  padding: 0 0 8px;
}

.custom-picker .react-datepicker__current-month {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 25px;
  display: none;
}

.custom-picker .react-datepicker__header__dropdown {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-picker .react-datepicker__month-read-view {
  background: #f8f7fa;
}

.custom-picker .react-datepicker__year-read-view,
.custom-picker .react-datepicker__month-read-view,
.custom-picker .react-datepicker__month-year-read-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  visibility: visible !important;
  min-width: 100px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.custom-picker .react-datepicker__year-dropdown {
  left: 35%;
}

.custom-picker .react-datepicker__month-dropdown,
.custom-picker .react-datepicker__month-year-dropdown {
  left: 16%;
}

.custom-picker .react-datepicker__month-read-view {
  background: #f8f7fa;
}

.custom-picker .react-datepicker__year-read-view {
  background: #fff;
}

.custom-picker .react-datepicker__year-dropdown-container--select,
.custom-picker .react-datepicker__month-dropdown-container--select,
.custom-picker .react-datepicker__month-year-dropdown-container--select,
.custom-picker .react-datepicker__year-dropdown-container--scroll,
.custom-picker .react-datepicker__month-dropdown-container--scroll,
.custom-picker .react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
  justify-content: center;
  align-items: center;
}

.custom-picker .react-datepicker__year-read-view--down-arrow,
.custom-picker .react-datepicker__month-read-view--down-arrow,
.custom-picker .react-datepicker__month-year-read-view--down-arrow {
  border-width: 1.5px 1.5px 0 0;
  right: 0;
  top: 0;
  position: relative;
  display: inline-flex;
  margin-left: 8px;
  height: 7px;
  width: 7px;
}

.custom-picker .react-datepicker__month-read-view--down-arrow,
.custom-picker .react-datepicker__month-year-read-view--down-arrow {
  border-color: #1a1c17;
}

.custom-picker .react-datepicker__year-read-view--down-arrow {
  border-color: #19181a;
  opacity: 0.5;
}

.custom-picker .react-datepicker__year-dropdown,
.custom-picker .react-datepicker__month-dropdown,
.custom-picker .react-datepicker__month-year-dropdown {
  top: 40px;
  background: #fff;
  border: 1px solid #dfe1e5;
  border-radius: 0 0 8px 8px;
}

.custom-picker .react-datepicker__year-option:first-of-type,
.custom-picker .react-datepicker__month-option:first-of-type,
.custom-picker .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-picker .react-datepicker__year-option,
.custom-picker .react-datepicker__month-option,
.custom-picker .react-datepicker__month-year-option {
  padding: 3px 0;
  color: #1a1c17;
}

.custom-picker .react-datepicker__year-option:hover,
.custom-picker .react-datepicker__month-option:hover,
.custom-picker .react-datepicker__month-year-option:hover {
  color: #1a1c17;
  background-color: #f5f5f5;
}

.custom-picker .react-datepicker__year-option.react-datepicker__year-option--selected_year,
.custom-picker .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.custom-picker .react-datepicker__month-year-option.react-datepicker__month-option--selected_month {
  color: #fff;
  background-color: #1a1c17;
}

.custom-picker .react-datepicker__navigation {
  display: none;
}

.custom-picker .react-datepicker__navigation.react-datepicker__navigation--years {
  display: block;
}

.custom-picker .react-datepicker__navigation-icon::before {
  color: #19181a;
  border-width: 2px 2px 0 0;
}

.custom-picker .react-datepicker__navigation--next {
  right: 15px;
}

.custom-picker .react-datepicker__navigation--previous {
  left: 15px;
}

.custom-picker .react-datepicker__day-name,
.custom-picker .react-datepicker__day,
.custom-picker .react-datepicker__time-name,
.custom-picker .react-datepicker__day--keyboard-selected {
  font-weight: 400;
  font-size: 13px;
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  color: #19181a;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
}

.custom-picker .react-datepicker__day-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #19181a;
}

.custom-picker .react-datepicker__day--selected.react-datepicker__day,
.custom-picker .react-datepicker__day--selected.react-datepicker__day--in-range,
.custom-picker .react-datepicker__day--keyboard-selected {
  background: #0b1848;
  color: #fff;
  border: 1px solid #0b1848;
}

.custom-picker .react-datepicker__day--in-range {
  background: #ebebeb;
  border: 1px solid #ebebeb;
}

.custom-picker .react-datepicker__day--in-range {
  border-radius: 0;
}

.custom-picker .react-datepicker__day--in-selecting-range {
  color: #19181a;
  background: #fff;
  border: 1px solid #fff;
}

.custom-picker .react-datepicker__day--range-end {
  background: #0b1848;
  color: #fff;
  border: 1px solid #0b1848;
  border-radius: 0 50% 50% 0;
}

.custom-picker .react-datepicker__day--range-start {
  border-radius: 50% 0 0 50%;
}

.custom-picker .react-datepicker__day--in-selecting-range:hover,
.custom-picker .react-datepicker__day:hover {
  border: 1px solid #dfe1e5;
}

.custom-picker .react-datepicker__day--outside-month {
  color: #a3a3a3;
}

/********************************************************************
 * Following Date pickerstyles unable to genarate from TailwindCss
 ********************************************************************/
.custom-picker-filter {
  width: 100%;
  border: none;
}

.custom-picker-filter * {
  outline: 0 !important;
}

.custom-picker-filter .react-datepicker__month {
  margin: 0px;
}

.custom-picker-filter .react-datepicker__month-container {
  width: 100%;
}

.custom-picker-filter .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: 0;
  padding: 0;
}

.custom-picker-filter .react-datepicker__current-month {
  font-size: 14px;
  line-height: 16px;
  @apply mb-3 text-grey-700 text-12s;
  display: none;
}

.custom-picker-filter .react-datepicker__header__dropdown {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-picker-filter .react-datepicker__month-read-view {
  background: #fff;
}

.custom-picker-filter .react-datepicker__year-read-view,
.custom-picker-filter .react-datepicker__month-read-view,
.custom-picker-filter .react-datepicker__month-year-read-view {
  @apply relative text-grey-700 text-12s h-6 border-grey-100 border-solid border flex justify-between items-center flex-row-reverse !visible min-w-[100px] w-auto rounded font-normal pl-2 px-2;
}

.custom-picker-filter .react-datepicker__year-dropdown {
  left: 110px;
}

.custom-picker-filter .react-datepicker__month-dropdown {
  left: 0;
}

.custom-picker-filter .react-datepicker__month-read-view {
  background: #fff;
}

.custom-picker-filter .react-datepicker__year-read-view {
  background: #fff;
}

.custom-picker-filter .react-datepicker__year-dropdown-container--select,
.custom-picker-filter .react-datepicker__month-dropdown-container--select,
.custom-picker-filter .react-datepicker__month-year-dropdown-container--select,
.custom-picker-filter .react-datepicker__year-dropdown-container--scroll,
.custom-picker-filter .react-datepicker__month-dropdown-container--scroll,
.custom-picker-filter .react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
  justify-content: center;
  align-items: center;
}

.custom-picker-filter .react-datepicker__year-read-view--down-arrow,
.custom-picker-filter .react-datepicker__month-read-view--down-arrow,
.custom-picker-filter .react-datepicker__month-year-read-view--down-arrow {
  border-width: 1px 1px 0 0;
  right: 0;
  top: 0;
  position: relative;
  display: inline-flex;
  margin-left: 8px;
  height: 7px;
  width: 7px;
}

.custom-picker-filter .react-datepicker__month-read-view--down-arrow,
.custom-picker-filter .react-datepicker__month-year-read-view--down-arrow {
  border-color: #1a1c17;
}

.custom-picker-filter .react-datepicker__year-read-view--down-arrow {
  border-color: #19181a;
  opacity: 0.5;
}

.custom-picker-filter .react-datepicker__year-dropdown,
.custom-picker-filter .react-datepicker__month-dropdown,
.custom-picker-filter .react-datepicker__month-year-dropdown {
  @apply bg-white top-6 shadow-dropDown border-grey-100;
  border-radius: 0 0 8px 8px;
}

.custom-picker-filter .react-datepicker__year-option:first-of-type,
.custom-picker-filter .react-datepicker__month-option:first-of-type,
.custom-picker-filter .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-picker-filter .react-datepicker__year-option,
.custom-picker-filter .react-datepicker__month-option,
.custom-picker-filter .react-datepicker__month-year-option {
  padding: 3px 0;
  color: #1a1c17;
}

.custom-picker-filter .react-datepicker__year-option:hover,
.custom-picker-filter .react-datepicker__month-option:hover,
.custom-picker-filter .react-datepicker__month-year-option:hover {
  color: #1a1c17;
  background-color: #f5f5f5;
}

.custom-picker-filter .react-datepicker__year-option.react-datepicker__year-option--selected_year,
.custom-picker-filter
  .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.custom-picker-filter
  .react-datepicker__month-year-option.react-datepicker__month-option--selected_month {
  color: #fff;
  background-color: #1a1c17;
}

.custom-picker-filter .react-datepicker__navigation {
  display: none;
}

.custom-picker-filter .react-datepicker__navigation.react-datepicker__navigation--years {
  display: block;
}

.custom-picker-filter .react-datepicker__navigation-icon::before {
  color: #19181a;
  border-width: 2px 2px 0 0;
}

.custom-picker-filter .react-datepicker__navigation--next {
  right: 15px;
}

.custom-picker-filter .react-datepicker__navigation--previous {
  left: 15px;
}

.custom-picker-filter .react-datepicker__day-names {
  margin-bottom: 0;
}

.custom-picker-filter .react-datepicker__day-name,
.custom-picker-filter .react-datepicker__day,
.custom-picker-filter .react-datepicker__time-name,
.custom-picker-filter .react-datepicker__day--keyboard-selected {
  @apply inline-block w-[30px] m-0 font-bold leading-[30px] mb-[2px] text-center bg-white border border-white border-solid text-14s rounded-2xl text-grey-900;
}

.custom-picker-filter .react-datepicker__day-name {
  @apply mb-3 font-bold uppercase text-12s text-grey-500;
}

.custom-picker-filter .react-datepicker__day--selected.react-datepicker__day,
.custom-picker-filter .react-datepicker__day--selected.react-datepicker__day--in-range,
.custom-picker-filter .react-datepicker__day--keyboard-selected {
  @apply bg-primary-75 border-primary-75;
}

.custom-picker-filter .react-datepicker__day--in-range {
  @apply bg-primary-75 border-primary-75;
}

.custom-picker-filter .react-datepicker__day--in-range {
  border-radius: 0;
}

.custom-picker-filter .react-datepicker__day--in-selecting-range {
  color: #19181a;
  background: #fff;
  border: 1px solid #fff;
}

.custom-picker-filter .react-datepicker__day--range-end {
  border-radius: 0 50% 50% 0;
}

.custom-picker-filter .react-datepicker__day--range-start {
  border-radius: 50% 0 0 50%;
}

.custom-picker-filter .react-datepicker__day--range-end.react-datepicker__day--range-start {
  border-radius: 50%;
}

.custom-picker-filter .react-datepicker__day--in-selecting-range:hover,
.custom-picker-filter .react-datepicker__day:hover {
  border: 1px solid #dfe1e5;
}

.custom-picker-filter .react-datepicker__day--outside-month {
  @apply text-grey-300 !font-medium;
}

.filter-picker.custom-picker-filter {
  @apply px-3 py-3 shadow-dropDown;
}

.filter-picker-wrapper .react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 0;
}

.filter-picker-wrapper .react-datepicker__input-container input {
  height: 28px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px;
  height: 28px;
  width: 234px;
  font-size: 12px;
  font-weight: 500;
}

/************************************************************
  Following options unable to handle through the datepicker 
 ************************************************************/
.termOptionTag,
.rangeOptionTag {
  @apply inline-flex;
}

.termOptionTag input,
.rangeOptionTag input {
  @apply absolute w-0 h-0 overflow-hidden opacity-0;
}

.rangeOptionTag span {
  @apply flex items-center justify-center font-bold bg-white rounded-lg cursor-pointer text-12s text-black-900;
  width: 35px;
  height: 30px;
  border: 1px solid #f2f2f2;
}

.rangeOptionTag input:checked + span {
  background: #0b1848;
  border: 1px solid #0b1848;
  @apply text-white;
}

/****/

.termOptionTag .text-wrapper {
  color: rgba(0, 0, 0, 0.87);
  @apply flex items-center justify-center font-light cursor-pointer text-12s;
}

.termOptionTag .icon-wrapper {
  color: #f0f0f5;
  border: 1px solid #e6e6eb;
  background: #f0f0f5;
  width: 20px;
  height: 20px;
  @apply flex items-center justify-center mr-1 rounded;
}

.termOptionTag input:checked + .text-wrapper .icon-wrapper {
  color: #fff;
  border: 1px solid #f2f2f2;
  background: #0011ff;
}

/************************************************************
  Style the analytics chart
 ************************************************************/

.recharts-surface,
.recharts-wrapper {
  width: calc(100% + 10px) !important;
  margin-left: -10px;
}

.recharts-tooltip-wrapper,
.recharts-tooltip-wrapper *,
.recharts-default-tooltip,
.recharts-default-tooltip *,
.recharts-rectangle,
.recharts-rectangle * {
  background: transparent !important;
}

.recharts-tooltip-wrapper {
  background: #263238 !important;
  /* Dark Shadow */
  box-shadow: 0px 4px 8px rgba(84, 110, 122, 0.24) !important;
  text-align: center;
  border-radius: 3px !important;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.recharts-tooltip-wrapper,
.recharts-tooltip-wrapper * {
  color: #fff;
  border: none !important;
  outline: 0 !important;
  margin: 0;
}

.recharts-cartesian-axis-tick-value {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #141415;
  text-transform: capitalize;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: none;
}

/************************************************************
  Style checkboxes in the grid
 ************************************************************/
.th-checkboxes {
  @apply w-full font-medium cursor-pointer text-16s text-black-900 whitespace-nowrap;
}

.th-checkboxes input,
.td-checkboxes input {
  @apply absolute w-0 h-0 p-0 m-0 overflow-hidden opacity-0 -left-4;
}

.th-checkboxes .selected {
  display: none;
}

.th-checkboxes input:checked ~ .selected {
  display: inline-flex;
}

.th-checkboxes input:checked ~ .non-selected {
  display: none;
}

.td-checkboxes {
  @apply inline-flex;
}

.td-checkboxes span {
  @apply flex items-center justify-center border border-solid rounded cursor-pointer border-gray-95;
}

.td-checkboxes input:checked + span {
  @apply border-black-900 bg-black-900;
}

.td-checkboxes span svg {
  stroke: white;
}

.td-checkboxes span * {
  border: none;
}

.__react_component_tooltip {
  border-radius: 6px !important;
  font-size: 14px !important;
  padding: 2px 12px !important;
  font-weight: 300 !important;
  font-style: normal !important;
  line-height: 18px !important;
}

/************************************************************
  Handle Grid Height
 ************************************************************/
.grid-height-normal .table-grid {
  height: calc(100vh - 310px);
}

.grid-height-small .table-grid {
  height: calc(100vh - 360px);
}

.grid-height-extraSmall .table-grid {
  height: calc(100vh - 325px);
}

.grid-height-medium .table-grid {
  height: calc(100vh - 220px);
}

.grid-height-large .table-grid {
  height: calc(100vh - 160px);
}

.grid-height-Mlarge .table-grid {
  height: calc(100vh - 205px);
}

.grid-height-batches .table-grid {
  height: calc(100vh - 300px);
}

.grid-height-medium,
.grid-height-large,
.grid-height-Mlarge,
.grid-height-batches {
  @apply pt-0 border-none;
}

.grid-modal,
.grid-modal-medium {
  @apply pt-0 border-b;
}

.table-grid tbody tr:last-child td {
  border: none !important;
}

.grid-modal .table-grid {
  max-height: calc(100vh - 380px);
  min-height: calc(100vh - 380px);
  @apply border-b border-solid border-grey-100;
}

.grid-modal-medium .table-grid {
  max-height: calc(100vh - 380px);
  min-height: calc(100vh - 380px);
  @apply border-b border-solid border-grey-100;
}

/************************************************************
  Multiselect-dropdown section item style
 ************************************************************/

.multiselect-dropdown {
  @apply relative flex w-full pr-8 overflow-hidden capitalize;
}

.multiselect-dropdown input,
.form-check-box input,
.form-check-box-right-aligned input {
  @apply absolute w-0 h-0 p-0 m-0 overflow-hidden capitalize -left-10;
}

.multiselect-dropdown .tick-icon {
  @apply right-3 top-2;
}

.multiselect-dropdown .tick-icon,
.form-check-box .tick-icon,
.form-check-box-right-aligned .tick-icon {
  @apply absolute flex items-center justify-center w-4 h-4 bg-white border border-solid rounded border-grey-300;
}

.form-check-box label {
  @apply relative pl-[28px];
  z-index: 2;
}

.form-check-box-right-aligned label {
  @apply relative pr-[28px] flex w-full;
  z-index: 2;
}

.form-check-box .tick-icon {
  @apply top-[5px] left-0;
  z-index: 1;
}

.form-check-box-right-aligned .tick-icon {
  @apply top-[2px] right-0;
  z-index: 1;
}

.multiselect-dropdown input:checked + .tick-icon,
.form-check-box input:checked + .tick-icon,
.form-check-box-right-aligned input:checked ~ .tick-icon {
  @apply bg-primary-500 border-primary-500;
}

/************************************************************
 Form Switch option style
 ************************************************************/

.form-switch-box input {
  @apply absolute w-0 h-0 p-0 m-0 overflow-hidden capitalize -left-10;
}

.form-switch-box .tick-icon {
  @apply absolute flex items-center justify-start h-5 overflow-hidden bg-white border border-solid rounded w-9 border-grey-300;
  background-color: rgb(186, 189, 194);
  border-radius: 13px;
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.form-switch-box .tick-icon-mover {
  @apply absolute w-4 h-4 bg-white rounded-lg top-[1px] left-[1px] right-auto items-center justify-center inline-flex;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  transition: ease 1s all;
}

.form-switch-box .tick-icon-mover svg {
  @apply w-[10px] h-[10px];
}

.form-switch-box label {
  @apply relative pr-[28px] flex w-full;
  z-index: 2;
  cursor: pointer;
}

.form-switch-box .tick-icon {
  @apply top-[2px] right-0;
  z-index: 1;
}

.form-switch-box input:checked ~ .tick-icon {
  @apply bg-primary-500 border-primary-500;
}

.form-switch-box input:checked ~ .tick-icon .tick-icon-mover {
  @apply top-[1px] right-[1px] left-auto text-primary-500;
}

/************************************************************
  style grid checkbox
 ************************************************************/
/* Main Classes */
.grid-checkbox[type='checkbox']:before {
  @apply relative block w-4 h-4 bg-white border-none;
  content: '';
}

.grid-checkbox[type='checkbox']:after {
  @apply relative block w-4 h-4 bg-white border border-solid rounded border-grey-300 -top-[15px];
  content: '';
}

.grid-checkbox[type='checkbox']:checked:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABhSURBVHgBhY4LDYAwDESHg0lAwiQhAQfgACmTMAs4mAQkHPvcfsnSXdK0vb40tylBAPbQnFpAOtSLLC+BtkDpM4dYuoOeAaLpaVruF/evQiVwBzs0mVkeg1GHFP4kdM/uP7BFi7bpEMJQAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: center;
  @apply bg-primary-500;
}

.grid-checkbox.grid-checkbox-row[type='checkbox']:checked:after {
  @apply absolute bg-primary-500 top-[18.5px];
  z-index: 2;
}

.grid-checkbox.grid-checkbox-row[type='checkbox']:checked:before {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 1px;
  right: 0;
  width: 100%;
  height: calc(100% - 2px);
  @apply bg-grey-50;
  z-index: 1;
}

.grid-cell-wrapper {
  z-index: 2;
}

.grid-checkbox[type='checkbox']:disabled:after {
  -webkit-filter: opacity(0.4);
}

.grid-checkbox[type='checkbox']:not(:disabled):checked:hover:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABhSURBVHgBhY4LDYAwDESHg0lAwiQhAQfgACmTMAs4mAQkHPvcfsnSXdK0vb40tylBAPbQnFpAOtSLLC+BtkDpM4dYuoOeAaLpaVruF/evQiVwBzs0mVkeg1GHFP4kdM/uP7BFi7bpEMJQAAAAAElFTkSuQmCC');
  @apply bg-primary-500;
}

.grid-checkbox[type='checkbox']:not(:disabled):hover:after {
  @apply bg-white border border-solid rounded border-grey-300;
}

.grid-checkbox[type='checkbox']:not(:disabled):hover:before {
  @apply bg-white border-none;
}

.grid-checkbox.grid-checkbox-row[type='checkbox']:not(:disabled):checked:hover:before {
  @apply bg-grey-50;
}

/*****************************
 * Set invoices wraper Max width
 ******************************/

.invoices-list-wrapper {
  height: calc(100vh - 335px);
  overflow-y: auto;
}

/********************************************
 * Set the tab naviagation active item style
 ********************************************/
.tab-navigation span {
  @apply bg-additional-purple-grey/30;
}

.tab-navigation.active span {
  @apply bg-additional-purple-grey;
}
