.react-datepicker__input-container input {
  width: 100%;
  width: 100%;
  height: 42px;
  padding-left: 16px;
  padding-right: 30px;
  color: #141518;
  font-weight: 400;
  font-size: 14px;
  background: white !important;
  border: 1px solid !important;
  border-color: rgba(60, 66, 87, 0.12) !important;
  border-radius: 8px;
  letter-spacing: 0.5px;
}

.react-datepicker__input-container {
  font-weight: 400;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  left: -25px;
}

.react-datepicker__year-read-view--down-arrow {
  top: 3px;
}

.react-datepicker__month-read-view--down-arrow {
  right: -14px;
  top: 3px;
  color: red;
}

.react-datepicker__year-dropdown-container--scroll {
  margin: 0px 2px 0px 18px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  left: -25px;
}

.react-datepicker-wrapper {
  /*border: 1px solid black !important;*/
  border-radius: 0.5rem;
}
